import axios from 'axios';
import moment from 'moment';
import validator from 'validator';
import 'react-calendar/dist/Calendar.css';
import { ToastContainer, toast } from 'react-toastify';
import draftToHtml from 'draftjs-to-html';
import React, {useEffect, useState, useCallback} from 'react';
import {FormProvider, useForm, Controller} from 'react-hook-form';
import { Layout, Select, Typography, Progress, Button, Form, Input, Radio, Row, Col, Checkbox, Slider, InputNumber, Collapse, Popconfirm, Spin } from 'antd';

const configByState = {
  dev: {
    open: 'https://api-dev.trialcat.net/apply',
    elegibility: 'https://api-dev.trialcat.net/apply',
    verify: 'https://api-dev.trialcat.net/apply'
  },
  stg: {
    open: 'https://api-stg.trialcat.net/apply',
    elegibility: 'https://api-stg.trialcat.net/apply',
    verify: 'https://api-stg.trialcat.net/apply'
  },
  prod: {
    open: 'https://api.trialcat.net/apply',
    elegibility: 'https://api.trialcat.net/apply',
    verify: 'https://api.trialcat.net/apply'
  }
};

const api = configByState[process.env.REACT_APP_MODE];

const { Header, Content } = Layout;

const apiCall = async (idPath) => {
  try {
    const response = await axios.get(`${api.open}/v1/form-open/${idPath}`);
    const {data} = response;
    const {result} = data || {};

    return result;
  } catch (err) {
    throw err;
  }
};

const postForm = async (data) => {
  try {
    const response = await axios.post(`${api.open}/v1/study-elegibility`, data);

    const {data: resData} = response;

    return resData;
  } catch (err) {
    throw err;
  }
};

const uploadVerifyForm = async (data) => {
  try {
    const response = await axios.post(`${api.open}/v1/verify`, data);

    const {data: resData} = response;

    return resData;
  } catch (err) {
    throw err;
  }
};

const postAppointmentInfo = async (data) => {
  try {
    const response = await axios.post(`${api.open}/v1/schedule-appointment`, data);

    const {data: resData} = response;

    return resData;
  } catch (err) {
    throw err;
  }
};

const optionsHear = [
  { label: 'Google', value: 'google', id: 1 },
  { label: 'Facebook', value: 'facebook', id: 2 },
  { label: 'Instagram', value: 'instagram', id: 3 },
  { label: 'Radio', value: 'radio', id: 4 },
  { label: 'Friends/Family', value: 'friends/family', id: 5 },
  { label: 'RDC Newsletter', value: 'rdc_newsletter', id: 6 },
];


const FormRender = () => {
  const [showForm] = useState(true);
  const [formId, setFormId] = useState([]);
  const [stepId, setStepId] = useState('');
  const [studyId, setStudyId] = useState('');
  const [savedId, setSavedId] = useState('');
  const [formFields, setFormFields] = useState([]);
  const [showError, setShowError] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [daysAvailable, setDaysAvailable] = useState({});
  const [goToSchedule, setGoToSchedule] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);
  const [inProgressValidate, setInProgressValidate] = useState(false);
  const [idPath] = useState(window.location.pathname?.replace('/', ''));
  const [usersPropsInformation, setUsersPropsInformation] = useState({});
  const [userPropsAppointment, setUserPropsAppointment] = useState({});
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);

  const formContext = useForm({
    mode: 'onChange'
  });

  const {watch} = formContext;

  const formContextContactDetail = useForm({
    mode: 'onChange'
  });

  const renderInputType = ({item, index}) => {
    const {type, fieldLabel, id, fieldDatabase, options, isRequired, maxValue, minValue, steper, isConditional, inputToCheckConditional, inputToCheckOptionConditional, editorToSave} = item;
    let show = true;

    if (isConditional) {
      const label = formFields?.find((x) => x?.fieldLabel === inputToCheckConditional);
      const valuesWatch = watch(label?.fieldDatabase);
      show = valuesWatch === inputToCheckOptionConditional;

      if (show) {
        var formHeight = document.body.scrollHeight;
        window.parent.postMessage({ id: formId, height: formHeight + 50 }, "*");
      }
    }
    

    switch (type) {
      case 'html':
        const markup = draftToHtml(editorToSave);
        return (
          <div style={{ display: show ? 'block' : 'none' }} dangerouslySetInnerHTML={{__html: markup }} />
        );
      case 'textField':
        return (
          <Controller
            key={id}
            name={fieldDatabase || fieldLabel?.replace(/\s/g, '')}
            rules={{ required: isRequired }}
            render={({field: {onChange, value}}) => (
              <Col span={24} style={{ width: '100%' }}>
                <Form.Item label={fieldLabel}>
                  <Input
                    label={fieldLabel}
                    onChange={(e) => onChange(e.target.value)}
                    style={{ width: '100%', marginTop: '20px' }}
                  />
                </Form.Item>
              </Col>
            )}
          />
        );
      
      case 'textFieldMultiline':
        return (
          <Controller
            key={id}
            name={fieldDatabase || fieldLabel?.replace(/\s/g, '')}
            rules={{ required: isRequired }}
            render={({field: {onChange, value}}) => (
              <Col span={24} style={{ width: '100%' }}>
                <Form.Item label={fieldLabel}>
                  <Input.TextArea onChange={(e) => onChange(e.target.value)} value={value} />
                </Form.Item>
              </Col>
            )}
          />
        );

      case 'dropdown':
        return (
          <Controller
            key={id}
            name={fieldDatabase || fieldLabel?.replace(/\s/g, '')}
            rules={{ required: isRequired }}
            render={({field: {onChange, value}}) => (
              <Col span={24} style={{ width: '100%' }}>
                <Form.Item label={fieldLabel}>
                  <Select onChange={(e) => onChange(e)} value={value} options={options} />
                </Form.Item>
              </Col>
            )}
          />
        );

      case 'numberInput':
        return (
          <Controller
            key={id}
            name={fieldDatabase || fieldLabel?.replace(/\s/g, '')}
            rules={{ required: isRequired }}
            render={({field: {onChange, value}}) => (
              <Col span={24} style={{ width: '100%' }}>
                <Form.Item label={fieldLabel}>
                  <InputNumber value={value} onChange={(e) => onChange(e.target.value)} />
                </Form.Item>
              </Col>
            )}
          />
        );

      case 'slider':
        return (
          <Controller
            key={id}
            name={fieldDatabase || fieldLabel?.replace(/\s/g, '')}
            rules={{ required: isRequired }}
            render={({field: {onChange, value}}) => (
              <Col span={24} style={{ width: '100%' }}>
                <Form.Item label={fieldLabel}>
                  <Slider min={minValue} max={maxValue} step={steper} value={value} onChange={(e) => onChange(e.target.value)} />
                </Form.Item>
              </Col>
            )}
          />
        );

      case 'checkbox':
        return (
          <Controller
            key={id}
            name={fieldDatabase || fieldLabel?.replace(/\s/g, '')}
            rules={{ required: isRequired }}
            render={({field: {onChange, value}}) => (
              <Col span={24} style={{ width: '100%' }}>
                <Form.Item label={fieldLabel}>
                  <Checkbox.Group options={options} value={value} onChange={(e) => onChange(e.target.value)} />
                </Form.Item>
              </Col>
            )}
          />
        );

      case 'radio':
        return (
          <Controller
            key={id}
            name={fieldDatabase || fieldLabel?.replace(/\s/g, '')}
            rules={{ required: isRequired }}
            render={({field: {onChange, value}}) => (
              <Col span={24} style={{ width: '100%' }}>
                <Form.Item label={fieldLabel}>
                  <Radio.Group options={options} value={value} onChange={(e) => onChange(e.target.value)} />
                </Form.Item>
              </Col>
            )}
          />
        )
  
      default:
        return null;
    }
  };

  const onSubmitContact = async (props) => {
    setInProgress(true);
    const user = {...props};
    const formData = {
      user,
      savedId,
    };

    try {
      if (process.env.REACT_APP_MODE === 'prod') {
        // eslint-disable-next-line no-undef
        fbq('track', 'Lead', {content_ids: "studyId"});
      }
      const {result: resultResponsePost} = await postForm(formData);
      const {daysAvailables} = resultResponsePost;
      // toast('Great, we will contact you soon');
      setShowError(false);
      setInProgress(false);
      setGoToSchedule(true);
      setUsersPropsInformation(user);
      setDaysAvailable(daysAvailables);
      formContextContactDetail.reset();
      formContext.reset();
    } catch (err) {
      console.log('ERR >>', err?.response?.data?.error?.message);
      toast(err?.response?.data?.error?.message ?? 'Sorry, could you try again');
      setInProgress(false);
    }
  }

  const validateQuestions = async (props) => {
    setInProgressValidate(true);
    const obj = {
      responses: {
        ...props,
      },
      studyId,
      stepId,
      formId
    };

    try {
      const {result: resultVerify} = await uploadVerifyForm(obj);
      const {isVerify, savedID} = resultVerify;
      setSavedId(savedID);
      setIsEligible(isVerify);
      setShowError(!isVerify);
      setInProgressValidate(false);
      setGoToSchedule(false);
    } catch (err) {
      setInProgressValidate(false);
      toast('Sorry, could you try again');
    }
  };

  const getForm = async () => {
    const form = await apiCall(idPath);
    const {formId, fields, id, stepId} = form;

    setIsLoadingForm(false);
    setFormFields(fields);
    setFormId(formId);
    setStepId(stepId);
    setStudyId(id);
  };

  const onSelectDay = async ({ day, time }) => {
    setIsLoadingSchedule(true);
    const obj = {
      appointment: {
        day,
        time
      },
      savedId
    };

    try {
      await postAppointmentInfo(obj);
      setIsLoadingSchedule(false);
      setShowError(false);
      setInProgressValidate(false);
      setUserPropsAppointment(obj);
      setShowConfirmationScreen(true);
    } catch (err) {
      setIsLoadingSchedule(false);
      toast('Sorry, could you try again');
    }
  };

  const sendFormHeight = useCallback(() => {
    var formHeight = document.body.scrollHeight;
    window.parent.postMessage({ id: formId, height: formHeight }, "*");
  }, [formId]);

  const onSubscribe = () => {
    window.location.href = "https://www.rdcclinical.com.au/general-recruitment/";
  }

  useEffect(() => {
    if (formId) {
      getForm();
      setIsLoadingForm(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  useEffect(() => {
    let height = document.body.offsetHeight;
    window.parent.postMessage({ height: height, step: showError ? 1 : isEligible ? 1 : 0 }, '*');
  }, [isEligible, showError]);

  useEffect(() => {
    if (formId) {
      sendFormHeight();
    }
  }, [formId, sendFormHeight]);

  return (
    <Layout>
        <Header style={{ backgroundColor: 'white', paddingTop: '10px' }}>
          {!showError && !showConfirmationScreen && (
            <Progress percent={isEligible ? goToSchedule ? 100 : 68 : 34} showInfo={false} strokeColor="#B8D947" />
          )}
        </Header>
      <Content style={{ marginLeft: '20px', marginRight: '20px' }}>

        {isLoadingForm && (
          <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
            <Spin spin style={{ colorPrimary: 'red' }} />
          </Row>
        )}

        {showForm && !isEligible && !showError && (
          <Row style={{ width: '100%' }}>
            {formFields?.length >= 1 && (
              <FormProvider {...formContext}>
                <Row style={{ width: '100%' }}>
                  <Form layout="vertical" style={{ width: '100%' }}>
                    {formFields?.map((item, index) => renderInputType({item, index}))}
                    {!savedId && (
                      <Button
                        type="primary"
                        style={{ width: '100%', backgroundColor: '#B8D947' }}
                        loading={inProgressValidate}
                        onClick={formContext.handleSubmit(validateQuestions)}>
                          Check Eligibility
                        </Button>
                    )}
                  </Form>
                </Row>
              </FormProvider>
            )}
          </Row>
        )}

        {isEligible && !goToSchedule && (
          <Form layout="vertical">
            <FormProvider {...formContextContactDetail}>
              <Controller
                name="firstName"
                rules={{ required: true }}
                render={({field: {onChange, value}}) => (
                  <Form.Item
                    label="First Name"
                    style={{ widows: '100%', marginTop: '20px' }}
                  >
                    <Input value={value} onChange={onChange}   />
                  </Form.Item>
                )}
              />

              <Controller
                name="lastName"
                rules={{ required: true }}
                render={({field: {onChange, value}}) => (
                  <Form.Item
                    label="Last Name"
                    style={{ widows: '100%', marginTop: '20px' }}
                  >
                    <Input value={value} onChange={onChange}   />
                  </Form.Item>
                )}
              />

              <Controller
                name="email"
                rules={{
                  required: true,
                  validate: (value) => {
                    return validator.isEmail(value);
                  }
                  
                }}
                render={({field: {onChange, value}}) => (
                  <Form.Item
                    label="Email"
                    style={{ widows: '100%', marginTop: '20px' }}
                  >
                    <Input value={value} onChange={onChange}   />
                  </Form.Item>
                )}
              />

              <Controller
                name="phone"
                rules={{
                  required: true,
                  validate: (value) => {
                    return validator.isMobilePhone(value);
                  }
                }}
                render={({field: {onChange, value}}) => (
                  <Form.Item
                    label="Telephone"
                    style={{ widows: '100%', marginTop: '20px' }}
                  >
                    <Input value={value} onChange={onChange}   />
                  </Form.Item>
                )}
              />

              <Controller
                name="hearAboutUs"
                rules={{ required: true }}
                render={({field: {onChange, value}}) => (
                  <Form.Item
                    label="How did you hear about us?"
                    style={{ widows: '100%', marginTop: '20px' }}
                  >
                    <Select value={value} onChange={(e) => onChange(e)} placeholder="How did you hear about us?" style={{ width: '100%', marginTop: '20px' }}>
                      {optionsHear?.map((item) => (
                        <Select.Option value={item.value} key={item.id}>{item.label}</Select.Option>
                      ))}
                  </Select>
                  </Form.Item>
                )}
              />

              <Row style={{ marginTop: '20px' }}>
                <Typography.Paragraph>
                  Personal Information Collection Notice
                </Typography.Paragraph>
              </Row>

              <Row>
                <Typography.Paragraph>
                  The personal information you provide when submitting this Eligibility Form will be used for the purpose of contacting you in relation to participating in our Clinical Studies. You may opt-out from receiving further communication from us at any time by phone or email
                </Typography.Paragraph>
              </Row>

              <Button
                type="primary"
                loading={inProgress}
                style={{ width: '100%', backgroundColor: '#B8D947' }}
                onClick={formContextContactDetail.handleSubmit(onSubmitContact)}
                disabled={!formContextContactDetail.formState.isDirty || !formContextContactDetail.formState.isValid}>
                  Submit
                </Button>
            </FormProvider>
          </Form>
        )}

        {isEligible && goToSchedule && !showConfirmationScreen && (
          <div>
            {isLoadingSchedule && (
              <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                <Spin spin style={{ colorPrimary: 'red' }} />
              </Row>
            )}
            <Typography.Title level={4} style={{ fontSize: '16px' }}>Thanks for registering.  You can now reserve a time for your screening phone call.</Typography.Title>
            <Collapse>
              {Object.keys(daysAvailable)?.map((item => (
                <Collapse.Panel header={moment(item).format('ddd Do MMMM')}>
                  {Object.keys(daysAvailable[item]).map((time) => (
                    <Popconfirm title={`Screening call on ${moment(item).format('ddd Do MMMM')} at ${time}`} okText="Yes" cancelText="No" onConfirm={() => onSelectDay({ day: item, time })}>
                      <Button style={{ marginRight: '10px', marginTop: '10px', backgroundColor: '#B8D947' }} type="primary">{time}</Button>
                    </Popconfirm>
                  ))}
                </Collapse.Panel>
              )))}
            </Collapse>
          </div>
        )}

        {isEligible && showConfirmationScreen && (
          <div>
            <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography.Title level={3} style={{ fontSize: '16px' }}>{`Hi ${usersPropsInformation?.firstName}, Thank you for booking your screening call.`}</Typography.Title>
            </Row>
            <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography.Title level={4} style={{ fontSize: '16px' }}>Your call is booked for:</Typography.Title>
            </Row>
            <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography.Title level={4} style={{ fontSize: '16px' }}>{`${userPropsAppointment?.appointment?.day ? moment(userPropsAppointment?.appointment?.day).format('DD/MM/YY') : ''} ${userPropsAppointment?.appointment?.time}`}</Typography.Title>
            </Row>
            <Row style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <strong>Please check your email for further information</strong>
            </Row>
          </div>
        )}

        {showError && (
          <>
            <Row>
              <Typography.Text>
                Oh no!
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                Thank you very much for taking the time to answer those questions. Unfortunately, based on your answers, this study will not be suitable for you to take part in. Thank you for your interest in our research.
              </Typography.Text>
            </Row>
            <Row>
              <Button onClick={onSubscribe} style={{ marginRight: '10px', marginTop: '10px', backgroundColor: '#B8D947' }} type="primary">subscriber</Button>
            </Row>
          </>
        )}

        <ToastContainer position="bottom-left" autoClose={5000} theme="dark" draggable />
      </Content>
    </Layout>
  );
};

export default FormRender;
