import React from 'react';
import { ConfigProvider } from 'antd';

import FormRender from './form';

function App() {
  return (
    <ConfigProvider theme={{
      token: {
          fontFamily: 'Lato',
        },
    }}>
      <FormRender />
    </ConfigProvider>
  );
}

export default App;
